import request from '@/utils/axios'

/** 获取用户列表 */
export function getList(params) {
  return request({
    url: '/message/get/user/list',
    method: 'get',
    params
  })
}

/** 批量通知用户 */
export function batchNotice(data) {
  return request({
    url: '/message/batch',
    method: 'post',
    data
  })
}

/** 通知所有用户 */
export function batchAllNotice(data) {
  return request({
    url: '/message/all',
    method: 'post',
    data
  })
}

/** 历史消息 */
export function historyMessage(params) {
  return request({
    url: '/message/history',
    method: 'get',
    params
  })
}
