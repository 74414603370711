<template>
  <div>
    <div style="margin-bottom:20px;">
      <div style="display: flex;align-items: flex-end;justify-content: space-between;margin-bottom:20px;">
        <div style="font-size:40px;">消息内容</div>
        <el-button type="primary" @click="historyMessage">历史发送消息记录</el-button>
      </div>
      <el-input
        v-model="content"
        type="textarea"
        :autosize="{ minRows: 4}"
        placeholder="请输入消息内容"
      />
    </div>
    <div class="filter-container">
      <el-form inline>
        <el-form-item>
          <el-input v-model="query.nickname" placeholder="请输入用户昵称" clearable />
        </el-form-item>
        <el-form-item>
          <el-input v-model="query.cellphone" placeholder="请输入手机号" clearable />
        </el-form-item>
        <el-form-item>
          <el-select v-model="query.realStatus" placeholder="请选择实名状态" clearable>
            <el-option v-for="(value, key) in realStatus" :key="key" :value="key" :label="value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="query.regTime"
            type="date"
            placeholder="请选择注册时间"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
          <el-button type="danger" icon="el-icon-refresh" @click="refresh">重置</el-button>
        </el-form-item>
        <el-form-item style="margin-left: 80px;">
          <el-button type="primary" @click="batch">批量通知</el-button>
          <el-button type="primary" @click="batchAllNotice">通知所有用户</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="list"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
      />
      <el-table-column
        prop="username"
        label="用户名"
        show-overflow-tooltip
      />
      <el-table-column
        prop="cellphone"
        label="手机号码"
        show-overflow-tooltip
      />
      <el-table-column
        prop="headUrl"
        label="用户头像"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          <el-image :src="row.headUrl" style="width: 50px;height: 50px" :preview-src-list="[row.headUrl]" />
        </template>
      </el-table-column>
      <el-table-column
        prop="realStatus"
        label="实名认证"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          {{ realStatus[row.realStatus] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="regTime"
        label="注册时间"
        show-overflow-tooltip
      />
    </el-table>
    <div class="page-container">
      <el-pagination
        background
        :total="count"
        :page-sizes="pageSizes"
        :page-size.sync="pageSize"
        :current-page.sync="page"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="refresh"
        @current-change="(val) => {
          page = val
          getList()
        }"
      />
    </div>
    <el-dialog
      title="历史发送消息记录"
      :visible.sync="show"
      width="80%"
    >
      <el-table
        :data="messageList"
      >
        <el-table-column
          prop="sendObject"
          label="发送对象的个数"
          show-overflow-tooltip
        />
        <el-table-column
          prop="sendContent"
          label="发送内容"
          show-overflow-tooltip
        />
        <el-table-column
          prop="sendTime"
          label="发送时间"
          show-overflow-tooltip
        />
      </el-table>
      <div class="page-container">
        <el-pagination
          background
          :total="mesCount"
          :page-sizes="mesPageSizes"
          :page-size.sync="mesPageSize"
          :current-page.sync="mesPage"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="messageRefresh"
          @current-change="(val) => {
            page = val
            historyMessage()
          }"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import list from '@/mixins/list'
import * as api from '@/api/message'
export default {
  mixins: [list],
  data() {
    return {
      api,
      content: '',
      realStatus: ['未认证', '认证申请中', '认证通过', '认证失败'],
      // 批量选中的ID
      ids: [],
      show: false,
      mesCount: 0,
      mesPageSizes: [10, 20, 30, 40, 50, 100],
      mesPageSize: 10,
      mesPage: 1,
      messageList: []
    }
  },
  methods: {
    // 当选择项发生变化时会触发该事件
    handleSelectionChange(val) {
      this.ids = val
    },
    batch() {
      if (this.ids.length) {
        var newIds = []
        this.ids.forEach(val => {
          newIds.push(val.id)
        })
        this.api.batchNotice({ ids: newIds, content: this.content }).then(() => {
          this.$message({
            message: '发送成功',
            type: 'success'
          })
          this.content = ''
        })
      } else {
        this.$message({
          message: '请勾选要通知的用户',
          type: 'error'
        })
      }
    },
    batchAllNotice() {
      this.api.batchAllNotice({ content: this.content }).then(() => {
        this.$message({
          message: '发送成功',
          type: 'success'
        })
        this.content = ''
      })
    },
    historyMessage() {
      this.show = true
      this.api.historyMessage({ page: this.mesPage, pageSize: this.mesPageSize }).then(res => {
        this.mesCount = res.data.count
        this.messageList = res.data.list
      })
    },
    messageRefresh() {
      this.mesPage = 1
      this.historyMessage()
    }
  }
}
</script>
